import React from "react";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import { useLocation, Link, BrowserRouter as Router } from "react-router-dom";
import algoLogo from "../../assets/logo-images/logo-color.png";
let { Toggle, Collapse } = Navbar;
let { Item } = NavDropdown;

const Header = () => {
  const location = useLocation();

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("header").classList.add("scroll-sticky");
    } else {
      document.getElementById("header").classList.remove("scroll-sticky");
    }
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Navbar
        className="top_navbar navbar-container-custom"
        sticky="top"
        id="header"
        expand="lg"
      >
        <Container className="px-3">
          <Link
            to="/"
            className={`${location.pathname === "/" ? "active" : ""}`}
          >
            <img
              src={algoLogo}
              alt="algo logo"
              style={{ maxHeight: "50px", objectFit: "cover" }}
            />
          </Link>

          <Toggle aria-controls="navbarScroll" />
          <Collapse className="justify-content-end pt-3" id="navbarScroll">
            <Nav className="me-auto navbar_links">
              <Link
                to="/"
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Link>
              {/* <Link to="/" className="nav-link">Services</Link> */}
              {/* <Link to="/project" className="nav-link">Ventures</Link> */}
              {/* <Link
                to="/service"
                className={`nav-link ${
                  location.pathname === "/service" ? "active" : ""
                }`}
              >
                {" "}
                Service
              </Link> */}
              <Link
                to="/about"
                className={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/blog"
                className={`nav-link ${
                  location.pathname === "/blog" ? "active" : ""
                }`}
              >
                Blog
              </Link>
              <Link
                to="/contact"
                className={`nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact
              </Link>
              <Link
                to="/faqs"
                className={`nav-link ${
                  location.pathname === "/faqs" ? "active" : ""
                }`}
              >
                FAQs
              </Link>
              <NavDropdown className="nav-link m-0 p-0" title="Services">
                <Item href="/service">All</Item>
                <Item href="/service/metaverse">Metaverse</Item>
                <Item href="/service/bcdev">Blockchain</Item>
                <Item href="/service/webdev">Web Development</Item>
                <Item href="/service/appdev">App Development</Item>
                <Item href="/service/gamedev">Game Devleopment</Item>
                <Item href="/service/devops">DevOps</Item>
              </NavDropdown>

              <NavDropdown className="nav-link" title="Partners">
                <Item onClick={() => openInNewTab("https://vorbit.org")}>
                  Vorbit
                </Item>
                <Item onClick={() => openInNewTab("https://nft.vorbit.org")}>
                  NFT Market Place
                </Item>
                <Item disabled>E-commerce</Item>
              </NavDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
  // }
};

export default Header;
