import "bootstrap/dist/css/bootstrap.min.css";

import {  Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import './assets/style/global.scss'

import routes from "./routes";

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback="">
        <Header />
          <Routes>
            {routes.map((data, index) => (
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact={true}
                path={data.path}
                element={data.component}
                key={index}
              />
            ))}
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
