import { lazy } from "react";

const Home = lazy(() => import("./Components/Home/Home"));
const Blog = lazy(() => import("./Components/Blog/Blog"));
const BlogDetail = lazy(() => import("./Components/Blog/BlogDetail"));
const Project = lazy(() => import("./Components/Project/Project"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const About = lazy(() => import("./Components/About/About"));
const Faq = lazy(() => import("./Components/FAQs/FAQs"));
const Service = lazy(() => import('./Components/Service/service.component'))
const ServiceDetail = lazy(() => import('./Components/Service/service-detail.component'))
const Admin = lazy(() => import('./Components/admin/admin.component'))


const routes = [
  { path: "/", component: <Home /> },
  { path: "blog", component: <Blog /> },
  { path: "blog/:blogId", component: <BlogDetail /> },
  { path: "service", component: <Service /> },
  { path: "service/:serviceUrl", component: <ServiceDetail /> },
  { path: "project", component: <Project /> },
  { path: "contact", component: <Contact /> },
  { path: "about", component: <About /> },
  { path: "faqs", component: <Faq /> },
  { path: "admin", component: <Admin /> },
];

export default routes;
